








































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import moment from 'moment'
import { getDicts } from '@/utils/dict'
import { getExportExcel } from '@/utils/export'
import { DatePicker, Dialog } from 'element-ui'
Vue.use(DatePicker)
Vue.use(Dialog)

@Component({
  components: {
    Table,
    Pagination,
    ImgView
  }
})
export default class RecordList extends Vue {
  $constants: any
  tabActive = ''
  // 字典数据
  // 小区期数
  areaDicts: any = []
  // 设备进组
  carGroupsIn: any = []
  // 设备出组
  carGroupsOut: any = []
  searchModel = {
    key: 'inStartTime',
    value: null
  }
  enterDeviceId = ''
  leaveDeviceId = ''
  listLoading = false
  tableHeader = [
    {
      key: 'inPhoto',
      name: '入场照片',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'carNo',
      name: '车牌号',
      width: '10%'
    },
    {
      key: 'inTime',
      name: '入场时间',
      width: '10%'
    },
    {
      key: 'inDoor',
      name: '位置',
      width: '10%'
    },
    {
      key: 'remark',
      name: '备注',
      width: '10%',
      remark: true
    },
    {
      key: 'outPhoto',
      name: '出场照片',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'outTime',
      name: '出场时间',
      width: '10%'
    },
    {
      key: 'outDoor',
      name: '位置',
      width: '10%'
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 10
  }
  imgUrl = ''
  isOpenModal = false
  dialogIsShow = false
  buttonLoading = false
  pickMinDate = ''
  pickOptions = {
    disabledDate: (v: any) => {
      if (this.pickMinDate !== '') {
        let maxTime = moment(this.pickMinDate).add(3, 'months')
        const minTime = moment(this.pickMinDate).subtract(3, 'months')
        if (maxTime > moment().endOf('day')) {
          maxTime = moment().endOf('day')
        }
        return moment(v) > maxTime || moment(v) < minTime
      }
      return moment(v) > moment().endOf('day')
    },
    onPick: (date: any) => {
      this.pickMinDate = date.minDate
      if (date.maxDate) {
        this.pickMinDate = ''
      }
    }
  }

  @Watch('tabActive')
  gettabActive() {
    this.getRecordList()
  }

  async beforeCreate() {
    // 获取相关字典
    // 小区期数
    this.areaDicts = await getDicts('PHASE')
  }

  mounted() {
    this.getCarGroupsOut()
    this.getCarGroupsIn()
    this.getRecordList()
  }

  tabAll() {
    this.tabActive = ''
  }

  tabChange(val: string) {
    this.tabActive = val
  }

  // 获取设备进入
  getCarGroupsIn() {
    this.$api.common
      .getAuthGroups({
        type: 2,
        size: '-1',
        direction: 1
      })
      .then((res: any) => {
        if (res.data.code === '200' && res.data.success) {
          this.carGroupsIn = res.data.data
        }
      })
  }

  // 获取设备离开
  getCarGroupsOut() {
    this.$api.common
      .getAuthGroups({
        type: 2,
        size: '-1',
        direction: 2
      })
      .then((res: any) => {
        if (res.data.code === '200' && res.data.success) {
          this.carGroupsOut = res.data.data
        }
      })
  }

  searchFn() {
    this.listQuery.page = 1
    this.getRecordList()
  }

  // 获取进出记录列表
  getRecordList() {
    this.listLoading = true
    this.$api.car
      .getRecordList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        area: this.tabActive,
        enterDeviceId:
          this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
        leaveDeviceId:
          this.leaveDeviceId !== '' ? Number(this.leaveDeviceId) : '',
        [this.searchModel.key]: this.searchModel.value,
        [this.searchModel.key === 'inStartTime'
          ? 'inStartTime'
          : 'outStartTime']: this.searchModel.value
          ? (this.searchModel.value as any)[0]
          : '',
        [this.searchModel.key === 'inStartTime'
          ? 'inEndTime'
          : 'outEndTime']: this.searchModel.value
          ? (this.searchModel.value as any)[1]
          : ''
        // inStartTime: this.searchModel.value
        //   ? (this.searchModel.value as any)[0]
        //   : '',
        // inEndTime: this.searchModel.value
        //   ? (this.searchModel.value as any)[1]
        //   : ''
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        if (data.success) {
          this.tableData = data.data
          this.total = data.total
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 批量导出
  doExport() {
    this.dialogIsShow = true
  }

  dialogConfirm() {
    if (
      !(
        (this.searchModel.key === 'inStartTime' ||
          this.searchModel.key === 'outStartTime') &&
        this.searchModel.value &&
        (this.searchModel.value as any)[0]
      )
    ) {
      this.buttonLoading = true
      const dayEnd = moment()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      const dayStart = moment(dayEnd)
        .subtract(3, 'months')
        .format('YYYY-MM-DD HH:mm:ss')
      const p1 = {
        exportType: 'carTravelRecord',
        area: this.tabActive,
        enterDeviceId:
          this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
        leaveDeviceId:
          this.leaveDeviceId !== '' ? Number(this.leaveDeviceId) : '',
        [this.searchModel.key]: this.searchModel.value,
        inStartTime: dayStart,
        inEndTime: dayEnd
      }
      getExportExcel(p1, '车辆进出记录导出.xlsx')
        .then(() => {
          this.dialogIsShow = false
          this.buttonLoading = false
        })
        .catch(() => {
          this.buttonLoading = false
          this.$message.error('下载出错')
        })
    } else {
      this.buttonLoading = true
      const p2 = {
        exportType: 'carTravelRecord',
        area: this.tabActive,
        enterDeviceId:
          this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
        leaveDeviceId:
          this.leaveDeviceId !== '' ? Number(this.leaveDeviceId) : '',
        [this.searchModel.key]: this.searchModel.value,
        [this.searchModel.key === 'inStartTime'
          ? 'inStartTime'
          : 'outStartTime']: this.searchModel.value
          ? (this.searchModel.value as any)[0]
          : '',
        [this.searchModel.key === 'inStartTime'
          ? 'inEndTime'
          : 'outEndTime']: this.searchModel.value
          ? (this.searchModel.value as any)[1]
          : ''
      }
      getExportExcel(p2, '车辆进出记录导出.xlsx')
        .then(() => {
          this.dialogIsShow = false
          this.buttonLoading = false
        })
        .catch(() => {
          this.buttonLoading = false
          this.$message.error('下载出错')
        })
    }
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 关闭对话框
  closeDialog() {
    this.dialogIsShow = false
    this.isOpenModal = false
  }
  // 操作对话框后统一回调
  afterOpt() {
    this.closeDialog()
  }

  toHistory(carNo: string) {
    this.$router.push({
      path: '/cars/record-history',
      query: {
        carNo
      }
    })
  }
}
